<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Hyvityslaskut</div>

    <v-card class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isAuthorized('invoice', 'create') && isRopoService"
          color="info"
          class="mr-1 mb-2"
          @click="$router.push('/invoice/create-refund-invoice')"
        >
          Uusi hyvityslasku
        </v-btn>
        <v-btn
          v-if="
            showBasedOnService({ talenom: false, ropoCapital: true }) &&
            isAuthorized('invoice', 'create')
          "
          color="primary mr-1 mb-2 ml-auto"
          dark
          @click="checkInvoices"
        >
          Tarkista maksut
        </v-btn>
      </v-card-title>

      <v-container>
        <!-- Search by dates -->
        <search-by-dates v-show="tab === 0"></search-by-dates>

        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              :label="getInvoiceSearchLabelByService('refundInvoice')"
              single-line
              hide-details
              solo
              append-icon="mdi-magnify"
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="mt-1">
          <v-col cols="6" md="4">
            <v-btn class="mr-5 mb-2" color="info" @click.prevent="getDataFromApi()">Etsi</v-btn>
            <v-btn class="mb-2" color="error" outlined @click="resetForms()">Tyhjennä</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="invoices"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <template #top>
          <!-- TABS -->
          <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="item in tabs" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>

        <!-- InvoiceNumber -->
        <template #[`item.invoiceNumber`]="{ item }">
          <span class="font-weight-bold"
            ># {{ item.invoiceNumber ? item.invoiceNumber : "-" }}
          </span>
        </template>

        <!--  billDate -->
        <template #[`item.billDate`]="{ item }">
          <span>{{ formatDate(item.billDate) }}</span>
        </template>

        <!--  accountDate -->
        <template #[`item.accountDate`]="{ item }">
          <span>{{ formatDate(item.accountDate) }}</span>
        </template>

        <!--  dueDate -->
        <template #[`item.dueDate`]="{ item }">
          <span>{{ formatDate(item.dueDate) }}</span>
        </template>

        <!-- totalAmount -->
        <template #[`item.totalAmount`]="{ item }">
          <div style="display: flex">
            <product-list :item="item" color="error"></product-list>

            <span
              v-if="item.paidAmounts.length > 0"
              style="cursor: pointer"
              @click="
                selected = { ...item };
                dialogPaidAmounts = true;
              "
              ><v-icon small>mdi-comment-edit-outline</v-icon></span
            >
          </div>
        </template>

        <!-- Apartment Address -->
        <template #[`item.contract`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="`/apartment/overview/${item.contract.apartment.id._id}`"
          >
            <span>{{ item.contract.apartment.id.address }}</span>
            <span v-if="item.contract.apartment.id.apartmentNumber"
              >, {{ item.contract.apartment.id.apartmentNumber }}
            </span>
          </router-link>

          <div v-for="(room, idx) in getRentedRooms(item.contract.apartment)" :key="idx + 'a'">
            <p class="draft--text" style="margin-bottom: -2px !important">{{ room.name }}</p>
          </div>
        </template>

        <!-- Tenant -->
        <template #[`item.tenant`]="{ item }">
          <div v-if="invoiceTenant(item).isReceiver">
            <router-link
              style="text-decoration: none"
              :to="`/tenant/overview/${invoiceTenant(item).tenant.tenantId._id}`"
            >
              <span>{{ item.tenant.name }}</span>
            </router-link>
          </div>

          <div v-else>
            <span>{{ item.tenant.name }}</span>
            <br />

            <router-link
              style="text-decoration: none"
              :to="`/tenant/overview/${invoiceTenant(item).tenant.tenantId._id}`"
            >
              <span>{{ invoiceTenant(item).tenant.tenantId.name }}</span>
            </router-link>
          </div>
        </template>

        <!-- SendTypes -->
        <template #[`item.sendType`]="{ item }">
          <span
            ><v-icon small>{{ getSendIcon(item.sendType) }}</v-icon>
          </span>
        </template>

        <!-- Outgoing payments -->
        <template #[`item.outgoingPayments`]="{ item }">
          <span v-if="item.outgoingPayments.inUse" class="font-weight-bold success--text"
            >Käytössä</span
          >
          <span v-else class="font-weight-bold error--text">Ei käytössä</span>
        </template>

        <!-- Comments -->
        <template #[`item.lastComment`]="{ item }">
          <span class="mr-1"
            >{{ formatCommentsInTableRow(item.comments) }}

            <v-btn
              icon
              small
              v-if="canUpdateInvoiceStatuses(item)"
              :loading="loadingStatuses"
              color="info"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small @click="getSingleInvoiceStatuses(item)"
                    >mdi-update</v-icon
                  >
                </template>
                Päivitä laskun tila
              </v-tooltip>
            </v-btn></span
          >

          <span
            v-if="item.comments.length > 0"
            style="cursor: pointer"
            @click="
              selected = item;
              dialogComments = true;
            "
            ><v-icon small>mdi-comment-edit-outline </v-icon></span
          >
        </template>

        <!-- Errors -->
        <template #[`item.sendingErrors`]="{ item }">
          <span
            v-if="item.sendingErrors.length > 0"
            style="cursor: pointer"
            @click="
              selected = item;
              dialogErrors = true;
            "
            ><v-icon color="error" small>mdi-comment-edit-outline</v-icon></span
          >
        </template>

        <!-- Actions -->

        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="isAuthorized('invoice', 'create') && !item.active"
              title="Lähetä hyvityslasku"
              small
              @click="
                selected = { ...item };
                sendInvoiceDialog = true;
              "
              >mdi-send
            </v-icon>

            <v-icon
              v-if="isAuthorized('invoice', 'edit') && !item.active && !hasServiceId(item)"
              title="Muokkaa"
              small
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >

            <v-icon
              v-if="
                isAuthorized('invoice', 'read') &&
                !item.active &&
                !hasServiceId(item) &&
                canDownloadPreviewInvoice(item)
              "
              class="grid-item"
              title="Esikatsele"
              small
              @click="
                downloadItem = { ...item };
                downloadPdf(true, null);
              "
              >mdi-magnify</v-icon
            >

            <v-icon
              v-if="isAuthorized('invoice', 'read') && hasServiceId(item)"
              title="Lataa pdf"
              small
              @click="
                downloadItem = { ...item };
                downloadPdf(false, null);
              "
              >mdi-file-pdf</v-icon
            >
            <v-icon
              v-if="isAuthorized('invoice', 'delete') && !item.active && canDeleteInvoice(item)"
              title="Poista"
              small
              @click="deleteItem(item)"
            >
              mdi-delete</v-icon
            >
          </div>
        </template>

        <template #no-data>
          <h2>Ei hyvityslaskuja</h2>
        </template>
      </v-data-table>

      <!-- Dialog send refund invoice -->
      <send-refund-invoice-dialog
        v-model="sendInvoiceDialog"
        :invoice="selected"
        @getdata="getDataFromApi()"
      ></send-refund-invoice-dialog>

      <!-- Dialog Errors -->
      <errors-dialog v-model="dialogErrors" :invoice="selected"></errors-dialog>

      <!-- Dialog comments -->
      <comments-dialog v-model="dialogComments" :invoice="selected"></comments-dialog>

      <!-- Dialog PaidAmounts -->
      <paid-amounts v-model="dialogPaidAmounts" :invoice="selected"></paid-amounts>
    </v-card>
  </div>
</template>

<script>
import CommentsDialog from "@/components/Invoice/CommentsDialog";
import ErrorsDialog from "@/components/Invoice/ErrorsDialog";
import PaidAmounts from "@/components/Invoice/PaidAmountsDialog";
import SearchByDates from "../../components/Invoice/SearchByDates.vue";
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import invoiceMixins from "@/mixins/invoiceMixins";
import { mapState, mapActions, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";
import invoiceHelpers from "@/utils/invoiceHelpers";
import SendRefundInvoiceDialog from "../../components/Invoice/SendRefundInvoiceDialog.vue";
import ProductList from "@/components/Invoice/TableComponents/ProductList";

export default {
  title: "Hyvityslaskut",
  mixins: [mixins, invoiceMixins],
  components: {
    ErrorsDialog,
    CommentsDialog,
    PaidAmounts,
    SearchByDates,
    SendRefundInvoiceDialog,
    ProductList,
  },
  data: () => ({
    tabs: ["Laskut", "Luonnokset"],
    dialogComments: false,
    dialogErrors: false,
    dialogPaidAmounts: false,
    sendInvoiceDialog: false,
    selected: { ropoCapital: {}, comments: [], sendingErrors: [], paidAmounts: [] },
    downloadItem: {},
    search: "",
    loading: true,
    options: {},
    allHeaders: [
      {
        text: "Laskun nro.",
        value: "invoiceNumber",
        show: [0],
        service: "all",
      },
      {
        text: "Hyvitys Ropo ID:een",
        value: "ropoCapital.refundToJobId",
        show: [0, 1],
        service: "ropoCapital",
      },
      {
        text: "Hyvitys Talenom laskuun",
        value: "talenom.refundToInvoiceNum",
        show: [0, 1],
        service: "talenom",
      },
      {
        text: "Laskun pvm.",
        value: "billDate",
        show: [0, 1],
        service: "all",
      },
      {
        text: "Kirjauspvm.",
        value: "accountDate",
        show: [0, 1],
        service: "all",
      },
      {
        text: "Eräpäivä",
        value: "dueDate",
        show: [0, 1],
        service: "all",
      },
      {
        text: "Sopimus",
        value: "contract.contractNumber",
        show: [0, 1],
        sortable: false,
        service: "all",
      },
      {
        text: "Saaja",
        value: "tenant",
        show: [0, 1],
        service: "all",
      },
      {
        text: "Kohde",
        value: "contract",
        show: [0, 1],
        service: "all",
      },
      {
        text: "Toimitus",
        value: "sendType",
        show: [1],
        service: "all",
      },
      {
        text: "Virheet",
        value: "sendingErrors",
        show: [1],
        service: "talenom",
      },
      {
        text: "Hyvitys",
        value: "totalAmount",
        show: [0, 1],
        service: "all",
      },
      {
        text: "Lähtevät maksut",
        value: "outgoingPayments",
        show: [7],
        sortable: false,
        service: "all",
      },
      {
        text: "Tila",
        value: "lastComment",
        show: [0],
        service: "all",
      },
      {
        text: "Toiminnot",
        value: "actions",
        show: [0, 1],
        sortable: false,
        service: "all",
      },
    ],

    globalValues,
  }),

  mounted() {
    if (this.$route.query.tab) {
      this.tab = Number(this.$route.query.tab);
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
  },

  computed: {
    ...mapState("invoice", ["totalLength", "invoices", "searchByDates", "loadingStatuses"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
    ...mapState("account", ["currentUser"]),

    headers() {
      const headers = this.allHeaders.filter((el) => {
        if (this.tab == 0) {
          return (
            el.show.includes(0) ||
            (el.show.includes(7) && this.currentUser.currentAccount.settings.outgoingPayments)
          );
        }

        if (this.tab == 1) {
          return (
            el.show.includes(1) ||
            (el.show.includes(7) && this.currentUser.currentAccount.settings.outgoingPayments)
          );
        }
      });

      // Modify headers depending what service account is using
      const modifiedHeaders = headers.filter((el) => {
        if (this.isRopoService) {
          return el.service === "ropoCapital" || el.service === "all";
        } else if (this.isTalenomService) {
          return el.service === "talenom" || el.service === "all";
        }
      });

      return modifiedHeaders;
    },

    tab: {
      get() {
        return this.$store.state.browsehistory.tab;
      },
      set(val) {
        this.$store.state.browsehistory.tab = val;
      },
    },
  },

  watch: {
    tab() {
      this.getDataFromApi();
    },

    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  created() {
    this.resetSearchByDates("dueDate");
  },

  methods: {
    ...mapActions("invoice", ["getAllInvoices", "deleteInvoice"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("invoice", ["resetSearchByDates"]),
    ...mapMutations("pdfViewer", ["openViewer"]),

    async getDataFromApi() {
      this.loading = true;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      const url = this.getInvoiceUrl();
      await this.getAllInvoices({ url });

      this.loading = false;
    },

    getInvoiceUrl() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const search = this.search.trim().toLowerCase();
      let additionalQuery = `&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      let url;

      if (this.tab == 0) {
        const start = this.searchByDates.startDate;
        const end = this.searchByDates.endDate;
        const searchBy = this.searchByDates.searchBy;
        additionalQuery =
          additionalQuery + `&startDate=${start}&endDate=${end}&searchBy=${searchBy}`;

        url = "/api/v1/invoices?refundInvoice=true&sendDate=exists";
      }
      if (this.tab == 1) {
        url = "/api/v1/invoices?refundInvoice=true&sendDate=null";
      }

      return url + additionalQuery;
    },

    editItem(item) {
      if (this.hasRefundServiceId(item)) {
        this.$router.push("/invoice/refund/edit-related/" + item._id);
      } else {
        this.$router.push("/invoice/refund/edit/" + item._id);
      }
    },

    async downloadPdf(preview, statusCode) {
      try {
        const data = await this.downloadInvoice(this.downloadItem, preview, statusCode);
        this.openViewer({
          data,
          name: `Hyvityslasku__${this.downloadItem.invoiceNumber || "esikatselu"}.pdf`,
        });
      } catch (err) {
        const errorMsg = err?.response?.data?.message || "";
        if (preview) this.showPopup(`Lataaminen ei onnistunut. ${errorMsg}`, "error");
        else this.showPopup(`${errorMsg}. Kokeile vaihtaa laskun tyyppiä`, "error");
      }
    },

    async deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa hyvityslaskun?")) {
        this.deleteInvoice(item._id);
      }
    },

    async checkInvoices() {
      try {
        // Check statuses
        let res = await axiosMethods.get(`/api/v1/ropo24/check-statuses`);
        if (res.data.statusLength == 0) {
          this.showPopup(`Yhdenkään laskun tila ei ole muuttunut.`, "success");
        } else {
          this.showPopup(`Laskujen tilat on päivitetty`, "success");
          this.$router.push("/refund-invoices?tab=0");
          this.$router.go();
        }
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    calcTotalAmount(products) {
      let totalAmount = 0;
      products.forEach((el) => {
        totalAmount += el.amount * (el.taxpr / 100 + 1) * el.count;
      });
      return totalAmount;
    },

    invoiceTenant(invoice) {
      return invoiceHelpers.getInvoiceTenant(invoice);
    },

    resetForms() {
      this.resetSearchByDates("dueDate");
      this.search = "";
      this.getDataFromApi();
    },
  },
};
</script>

<style scoped></style>
